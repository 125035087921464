import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { ResourcesOfficeService } from '@libs/app.services/office/resources-office.service';

@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<boolean> {
  constructor(private resourcesOfficeService: ResourcesOfficeService) {}

  resolve(): Observable<boolean> {
    if (this.resourcesOfficeService.countries.length > 0) return of(true);
    return this.resourcesOfficeService.fetchCountries().pipe(map(() => true));
  }
}
