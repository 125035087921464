import { Injectable } from '@angular/core';
import { first, map, of, switchMap, tap } from 'rxjs';
import { PusherOfficeService } from './pusher.service';
import { AuthOfficeService } from './auth.office.service';
import { Store } from '@ngrx/store';
import { UserOfficeService } from './users/users.service';
import { EmployeesService } from './employees.service';
import { ResourcesOfficeService } from './resources-office.service';
import { LangService } from '../lang.service';
import { DeviceService } from '../device.service';
import { Router } from '@angular/router';
import { TableDrawsService } from '../bicho/table.draws.service';
import { AwakeService } from '../awake.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigOfficeService {
  private currentUrl = window.location.href.replace(window.location.origin, '');
  constructor(
    private config: ResourcesOfficeService,
    private langService: LangService,
    private pusher: PusherOfficeService,
    private service: EmployeesService,
    private auth: AuthOfficeService,
    private userService: UserOfficeService,
    private store: Store,
    private deviceService: DeviceService,
    private router: Router,
    private tableDrawsService: TableDrawsService,
    private awakeService: AwakeService,
  ) {}
  init() {
    this.awakeService.init();
    this.tableDrawsService.app.set('office');
    this.langService.init();
    this.deviceService.init();
    return this.resources();
  }
  private resources() {
    return this.config.fetchBaseResources().pipe(
      switchMap(() => this.auth.init()),
      switchMap((logged: boolean) => (logged ? this.onAuth() : of(true))),
      first(),
    );
  }
  private onAuth() {
    return this.onToken().pipe(
      tap(() => this.pusher.init()),
      map(() => true),
    );
  }
  private onToken() {
    return this.service.fetchEmployee().pipe(
      switchMap(() => this.userService.fetchUsersLocalstore()),
      switchMap(() => this.config.fetchRecentsNotifications()),
      tap(() => this.store.dispatch({ type: '[Office] Load users stats' })),
      tap(() => this.currentUrl == '/' && this.router.navigateByUrl('/main')),
    );
  }
}
