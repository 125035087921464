import { createReducer, on } from '@ngrx/store';
import { assign } from 'lodash';
import { addResources } from './actions';
import { IResources } from './model';
const initialState: Readonly<IResources> = {
  drawsBase: [],
  draws_schedules: [],
  settings: null,
  wallets: [],
};
export const resourcesReducer = createReducer(
  initialState,
  on(addResources, (state, data) => assign({}, state, data)),
);
