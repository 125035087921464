@if (inti$ | async) {
  <div class="wrapper">
    <router-outlet></router-outlet>
  </div>
  <app-toast />
  <app-modal />
} @else {
  <div class="wrapper spinner">
    <app-spinner />
    <p>Loading resources</p>
  </div>
}
