import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { AuthOfficeService } from '@libs/app.services/office/auth.office.service';

@Injectable()
export class AuthOfficeInterceptor implements HttpInterceptor {
  constructor(private auth: AuthOfficeService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    const isRefreshing = req.url.indexOf('auth/refresh/office') > -1;
    return (isRefreshing ? this.cloneRefresh(next, req) : this.cloneJwt(next, req)).pipe(
      catchError((err: any) => {
        if (err.status === 401) {
          return isRefreshing
            ? of(this.auth.logout())
            : this.auth.refresh().pipe(concatMap(() => this.cloneJwt(next, req)));
        } else {
          return throwError(err).pipe(map(() => ({ error: true, error_desc: err })));
        }
      }),
    );
  }
  cloneJwt(next: HttpHandler, req: HttpRequest<any>) {
    return next.handle(
      req.clone({
        headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${this.auth.token()}` }),
      }),
    );
  }
  cloneRefresh(next: HttpHandler, req: HttpRequest<any>) {
    return next.handle(
      req.clone({ headers: new HttpHeaders({ Authorization: `Bearer ${this.auth.refresh_token()}` }) }),
    );
  }
}
